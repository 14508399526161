<template>
    <r-e-dialog title="查看附件" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh">
        <el-form ref="formPublish" label-width="90px" size="small">
            <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                    <div data-v-037521bc="" class="title">图片附件</div>
                </div>
            </div>
            <div class="flex" style="height: 180px;overflow:scroll;">
                <div v-for="(item,index) in imgList" :key="index">
                    <div class="flex justify-center"
                         style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">
                        <el-image :src="createFullImageUrl(item)" class="flex align-center"
                                  @click="look(createFullImageUrl(item))">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"/>
                            </div>
                            <div slot="placeholder">加载中...</div>
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="el-descriptions__header">
                <div class="el-descriptions__title">
                    <div data-v-037521bc="" class="title">PDF附件</div>
                </div>
            </div>
            <div class="flex justify-between" style="height: 400px;overflow:scroll;">
                <div v-for="(item,index) in filePDFList" :key="index">
                    <iframe :src="createFullImageUrl(item)" style="border: none;" width="430px" height="600px">
                        PDF无法正常显示，请您点击查看:
                        <a href="https://soil.neoyon.com/hicity/applet/rent/files?uuid=5ed72644f3ae4a5b85d96faaf5ff018d">View
                            pdf</a>
                    </iframe>
                </div>
            </div>
        </el-form>
        <el-dialog :visible.sync="lookDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </r-e-dialog>
</template>

<script>
import {createFullImageUrl} from "@/components/Upload/index"

export default {
    name: "dialog-look-contract-file",
    data() {
        return {
            dialogVisible: false,
            filePDFList: [],
            imgList: [],
            lookDialogVisible: false,
            dialogImageUrl: null
        }
    },
    props: {},
    methods: {
        //打开合同预览弹框
        openDialog(data) {
            //获取合同附件文件
            let {contractPhotos, pdfUrl} = data;
            //截取图片文件uuid字符串
            let imgList = contractPhotos !== null && contractPhotos !== "" ? contractPhotos.split("+-+").filter(item => item !== "") : [];
            //截取PDF文件uuid字符串
            let filePDFList = pdfUrl !== null && pdfUrl !== "" ? pdfUrl.split("+-+").filter(item => item !== "") : [];
            //拆分图片文件uuid
            this.imgList = imgList.length === 2 ? imgList[1].split(",") : [];
            //拆分图片文件uuid
            this.filePDFList = filePDFList.length === 2 ? filePDFList[1].split(",") : [];
            //打开弹窗
            this.dialogVisible = true;
        },
        //转换图片地址方法
        createFullImageUrl(uuid) {
            return createFullImageUrl(uuid)
        },
        //打开大图查看图片弹窗方法
        look(url) {
            //打开大图查看图片弹窗
            this.lookDialogVisible = true;
            //图片url赋值
            this.dialogImageUrl = url;
        }
    }
}
</script>

<style scoped>

</style>
